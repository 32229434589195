/* global css colors */
/* main */

html {
  scroll-behavior: smooth;
}

body{ 
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #ececec;
  width: 100vw;
  overflow-x: hidden;
}


* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  box-sizing: border-box;
}

* {
  /* border: 1px solid red !important; */
}
* {
  -webkit-overflow-scrolling: touch;
}

.App {
  text-align: center;
  background-color: #000000;
  position: relative;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* mobile */
@media screen and (max-width: 768px){
  .App-header{
    /* padding: 0px 20px; */
    width: 100%;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero{
  position: relative;
  background-color: #1E2434;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  /* width: 100%; */
  height: 100%;

}

.hero-background{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("../public/imgs/backgrounds/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* flip background */
  transform: scaleX(-1);
  z-index: 0;
  }

.hero-background::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* gradient overlay from bottom left to top right */
  background-image: linear-gradient(to top left, #0b0b0be2, #1e2434af 45%, transparent 90%);

}

/* after that put another gradient at bottom to top */
.hero-background::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* gradient overlay from bottom left to top right */
  background-image: linear-gradient(to top, #000000, #00000088 20%, transparent 60%);

}

.hero-left{
  position: relative;
  z-index: 1;
  padding-left: 10rem;
  padding-top: 10rem;
  padding-bottom: 30rem;
  text-align: left;
  color : #ececec;
}

@media screen and (max-width: 1064px){
  .hero-left{
    padding-left: 2rem;
    padding-top: 5rem;
    padding-bottom: 20rem;
  }
}

.hero-name{
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.hero-role{
  font-size: 1.5rem;
  font-weight: 200;
  margin-bottom: 10px;
}


.skills-container {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  /* grid */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}

@media screen and (max-width: 1064px){
  .skills-container{
    grid-template-columns: repeat(1, 1fr);
    padding: 1rem;
    grid-gap: 1rem;
  }
}

.section {
  padding-top: 5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1064px){
  .section{
    padding: 5rem 2rem;
    overflow-x: hidden;
  }
}
.otherskills-container {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  /* second row should have offset */
  /* margin-left: 10rem; */
}

.otherskills-container > .skills-pill {
  margin: 5px;
}

/* under 1200 */
@media screen and (max-width: 1200px){
  .otherskills-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  }
  .otherskills-container > .skills-pill {
    margin: .5rem;
  }
}

/* second row should have offset */
/* .otherskills-container > div:nth-child(2n+1){
  margin-left: 10rem;
} */


.shooting-star{
  position: absolute;
    top:50%;
    left:50%;
    width: 4px;
    height: 4px;
    background: #ffffff41;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1),0 0 0 4px rgba(255,255,255,0.1),0 0 10px rgba(255,255,255,0.1);
    animation: animate 3s linear infinite;
}

.shooting-star::before{
  content:'';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 150px;
  height: 1px;
  background: linear-gradient(90deg,#ffffff5b,transparent);
}

@keyframes animate
{
    0%
    {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }
    70%
    {
        opacity: 1;
    }
    100%
    {
        transform: rotate(315deg) translateX(-1000px);
        opacity: 0;
    }
}

.shooting-star:nth-child(1){
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 2s;
  animation-duration: 8s;
}
.shooting-star:nth-child(2){
  top: 0;
  right: 80px;
  left: initial;
  animation-delay: 3s;
  animation-duration: 6s;
}
.shooting-star:nth-child(3){
  top: 80;
  right: 0px;
  left: initial;
  animation-delay: 4s;
  animation-duration: 5s;
}
.shooting-star:nth-child(4){
  top: 0;
  right: 180px;
  left: initial;
  animation-delay: 5s;
  animation-duration: 3s;
}
.shooting-star:nth-child(5){
  top: 0;
  right: 400px;
  left: initial;
  animation-delay: 0.8s;
  animation-duration: 5;
}
.shooting-star:nth-child(6){
  top: 0;
  right: 600px;
  left: initial;
  animation-delay: 1s;
  animation-duration: 3s;
}
.shooting-star:nth-child(7){
  top: 300px;
  right: 0px;
  left: initial;
  animation-delay: 1.2s;
  animation-duration: 1.75s;
}
.shooting-star:nth-child(8){
  top: 0px;
  right: 700px;
  left: initial;
  animation-delay: 1.4s;
  animation-duration: 1.25s;
}

.space-station{ 
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  /* background-color: #000000; */

  /* change color of svg */
  /* opacity: 0.5; */
  /* flip background */ 
  /* transform: scaleX(-1); */
  z-index: 0;
  animation: moveSpaceStationAcrossScreen 20s linear infinite;
}

.space-station-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: url("../public/imgs/space-station.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: .2;
  /* transform: rotate(30deg); */
}



.space-station:before{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20%;
  width: 20%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: blink 1s infinite;
}

/* blinking red light on the space-station */
@keyframes blink {
  0% {
    background-color: #00000000;
  }
  50% {
    background-color: #ff0000;
  }
  100% {
    background-color: #00000000;
  }
}

@keyframes moveSpaceStationAcrossScreen {
  0%, 100% {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  12.5% {
    left: 12.5%;
    top: 40%;
  }
  25% {
    left: 25%;
    top: 30%;
  }
  37.5% {
    left: 37.5%;
    top: 40%;
  }
  50% {
    left: 50%;
    top: 50%;
  }
  62.5% {
    left: 62.5%;
    top: 60%;
  }
  75% {
    left: 75%;
    top: 70%;
  }
  100% {
    left: 100%;
    top: 60%;
  }
  
}

.contact-container{
  overflow: hidden;
}

.contact-column{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; 
  margin: 3rem 2rem;
  flex-wrap: wrap;
}

.contact-info{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 2rem;
  width: 100%;
  flex-wrap: wrap;
  text-wrap: break-word;
  overflow: hidden;
}

.contact-header{
  margin-bottom: 10px;
  font-weight: 300;
  position: relative;
  font-size: 1.8rem;
  /* text-transform: uppercase; */
  text-decoration: none;
  color: #ffffffad;
  font-weight: 500;
  
}

/* add arrow after contact-header */
/* .contact-header::after{
  content: "->";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #ffffffad;
} */



