.navbar{
    background-color: #f8f8f800;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 100;
    padding: 1rem;
    /* gradient top down */
    background-image: linear-gradient(to bottom, #1E2434, #f8f8f800);
}



.navbar-link-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    padding: 0px 20px;

}

.navbar-link{
    color: #dedede;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 300;
    padding: 0px 20px;
    transition: all 0.3s ease-in-out;
    margin: 0px 3rem;
}

.navbar-mobile{
    position: relative;
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.navbar-mobile-hamburger{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 1rem;

}

.navbar-mobile-hamburger-line{
    width: 100%;
    height: 1px;
    background-color: #dedede;
    border-radius: 5px;
}

@media screen and (max-width: 1200px) {
    .navbar-mobile{
        display: flex;
        z-index: 100;
    }

    .navbar-link-container{
        display: none;
    }
}

.navbar-mobile-menu{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    background: #1e2434fc;
    width: 100%;
    padding: 2rem 1rem;
    z-index: 50;
    text-align: left;
}

.navbar-mobile-menu-link{
    color: #dedede;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 300;
    padding: 0px 5px;
    /* transition: all 0.3s ease-in-out; */
    margin: 0px 3rem;
    display: block;
    margin-bottom: 1rem;
}

.navbar-mobile-open {
    display: flex;
    animation: openNav 0.3s ease-in-out;
    animation-fill-mode: forwards;
    /* play the same animation backwards when removed */
    top: 0;
    left: 0;
}

/* when removed play animation in reverse */
.navbar-mobile-close {
    /* display: flex;   */
    animation: closeNav 0.3s ease-in-out;
    animation-fill-mode: forwards;
    top: 0;
    left: 0;
    opacity: 0;

}

@keyframes openNav {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes closeNav {
    0% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}