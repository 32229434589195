.project{
    display: flex;
    flex-direction: row;
    /* background-color: #fff; */
    font-weight: 300;
    border-radius: 5px;
    padding: 2rem;
    margin: 7rem 0;
    color: #333;
    text-wrap: wrap;
    overflow: hidden;
}

/* under 1200 */


/* every other project reverse, other children in div as well */

.project:nth-child(odd of .project) {
    flex-direction: row-reverse;
}


@media screen and (max-width: 1200px){
    .project{
        flex-direction: column;
        margin: 2rem 0;
        width: 100%;
        justify-content: center;
        align-items: center;
    }



    .project:nth-child(odd of .project) {
        flex-direction: column-reverse;
    }

}

.project-img-container{
    width: 50%;
    margin: 1rem 2rem;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 2rem;
    transition: all 0.3s ease-in-out;
}

.project-img{
    /* height: 300px; */
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.project-img.fade-out {
    opacity: 0;
  }

.project-info{
    width: 50%;
    text-align: left;
    color: #dddbdb;
}

/* under 1200 */
@media screen and (max-width: 1200px){
    .project-info{
        width: 100%;
        text-align: left;
    }

    .project-img-container{
        width: 100%;
        margin: 1rem 0;
    
    }

    .project-name{
        width: 100%;
        text-align: center;
    }
}

.project-skills-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin-left: 2rem;

}

.project-info-column > div{
    padding: 1rem 0;
}

@media screen and (max-width: 1200px){
    .project-info-column{
        margin: 1rem 0;
    }

    .project-info-column > div{
        padding: 1rem 0;
    }
    
}

.project-name{
    font-size: 2rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;
}

@media screen and (min-width: 1200px) {
    
}

.project-header{
    font-size: 1.8rem;
    /* text-transform: uppercase; */
    color: #ffffffa0;
    font-weight: 500;
}

.project-features-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 2rem;
}



.project-feature{
    font-size: 1rem;
    
}

.project-feature::before{
    content: "•";
    color: #105987;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}