.skills-bar{
    position: relative;
    background-color: #1E2434;
    border-radius: 5px;
    padding: 5px 10px;
    /* margin: 3rem 1rem; */
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #ececec;
    border: 1px solid #515E96;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color: #333;
        color: #f5f5f5;
    };
    text-transform: capitalize;
    overflow: hidden;
    height: 200px;
}

/* .open{
    animation: wipe-enter 1s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
}





@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
    100% {
        transform: scale(1, 1);
    }
} */


/* on mobile make it column */
@media screen and (max-width: 1064px){
    .skills-bar{
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
    }
}

@media screen and (max-width: 1700px){
    .skills-bar{
        /* width: 100%; */
        height: 100%;
    }
}

.closed{
    height: 50px;
    width: 100%;
}


.skills-bar-img-container{
    position: relative;
    transition: all 0.3s ease-in-out;
    width: 30%;
    height: 100%;
    margin: 0 1rem;
}

@media screen and (max-width: 1700px) and (min-width: 1064px){
    .skills-bar-img-container{
        /* width: 100%; */
        height: 100px;
        width: 100%;
        /* height: 20%; */
        margin-left: 2rem;
        margin: auto 2rem;
        /* margin-top: 3rem; */
    }
}

@media screen and (max-width: 1064px){
    .skills-bar-img-container{
        width: 100%;
        height: 150px;
        /* height: 20%; */
        margin: 0;
        margin-top: 3rem;
    }
    
}

.skills-bar-img{
    /* width: 100%; */
    /* position: absolute;
    top: 0px;
    left: 0px; */
    /* center img */
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);

    transition: all 0.3s ease-in-out;
    /* transform: rotate(-30deg); */
}


.skills-bar-info{
    position: relative;
    z-index: 1;
    /* padding-left: 40%; */
    padding-right: 20px;
    height: 100%;
    width: 70%;
    padding-bottom: 1rem;
    /* padding-top: 15%; */
    text-align: left;
    color : #ececec;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    transition: all 0.3s ease-in-out;
}

/* smaller screens */
@media screen and (max-width: 1700px){
    .skills-bar-info{
        width: 100%;
        height: 200px;
        flex-direction: column;
        align-items: flex-end;
        padding: 0;
        /* margin: 1rem; */
        margin: 4rem 1rem;
        margin-bottom: 1rem;
        text-align: right;
    }
}


@media screen and (max-width: 1064px){
    .skills-bar-info{
        width: 90%;
        height: auto;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-around;
        text-align: left;
        /* align-items: flex-start; */
        padding: 0;
        /* margin-top: 1rem; */
        margin-bottom: 3rem;
        /* margin: 0 1rem; */
        /* padding-top: 2rem; */
    }
}

@media screen and (max-width: 600px){
    .skills-bar-info{
        width: 90%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        text-align: left;
        /* align-items: flex-start; */
        padding: 0;
        margin-top: 0;
        margin-bottom: 1rem;
        /* margin-top: 1rem; */
        /* margin-bottom: 3rem; */
        /* margin: 0 1rem; */
        /* padding-top: 2rem; */
    }
}



.skills-bar-info-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;


}

.skills-bar-info-column h2{
    /* font-size: 1.5rem; */
}
.skills-bar-info-column h3{
    /* font-size: 1.5rem; */
    color: #979797cd;
    text-transform: uppercase;
}

.skills-bar-level{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 100%;
    /* width: 0%; */
    text-align: right;
}

.skills-bar-level h3 {
    color: #979797cd;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}




