.section-header{
    font-size: 2rem;
    color: #ececec;
    margin-bottom: 10px;
    position: relative;
}

.section-header > h1 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 3rem;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: .5rem;

}

.section-header::after{
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 50px;
    background-color: #105987;
}