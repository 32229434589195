.divider {
    position: relative;
    width: 60%;
    height: 1px;
    background-color: #515e96cc;
    /* margin: 20px 0; */
    margin: 0 auto;
}

/* opacity fades on left and right */
.divider::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100px;
    height: 100%;
    width: 100px;
    background-image: linear-gradient(to left, #515e96cc, transparent);
}

.divider::after {
    content: "";
    position: absolute;
    top: 0;
    right: -100px;
    height: 100%;
    width: 100px;
    background-image: linear-gradient(to right, #515e96cc, transparent);
}