.skills-pill{
    background-color: #1E2434;
    border-radius: 5px;
    padding: .5rem 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #ececec;
    border: 1px solid #515E96;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    text-wrap: pretty;
    /* &:hover{
        background-color: #333;
        color: #f5f5f5;
    }; */
}

.skills-pill-link{
    color: #ececec;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color: #333;
        color: #f5f5f5;
    };
    cursor: pointer;
    
}


/* under 1200 */
@media screen and (max-width: 1200px){
    .skills-pill{
        font-size: 1rem;
        padding: .25rem 1rem;

    }
}